import React from 'react';
import { Table, List, ThemeIcon, Spoiler } from '@mantine/core';
import { CircleCheck } from 'tabler-icons-react';
import Layout from '../../components/Layout';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import Tabs from '../../components/Tabs';
import awolola from '../../assets/images/departments/anatomic/awolola.png';
import badmus from '../../assets/images/departments/anatomic/badmus.png';
import charles from '../../assets/images/departments/anatomic/charles.png';
import daramola from '../../assets/images/departments/anatomic/daramola.png';
import fatimah from '../../assets/images/departments/anatomic/fatimah.png';
import fehintola from '../../assets/images/departments/anatomic/fehintola.png';
import osadolor from '../../assets/images/departments/anatomic/osadolor.png';

const Anatomic = () => {
  const services = [
    {
      name: '	Autopsy Pathology ',
    },
    {
      name: '	Breast pathology',
    },
    { name: '	Cytopathology ' },
    {
      name: '	Dermatopathology ',
    },

    {
      name: '	Dermatopathology',
    },
    {
      name: '	Gastrointestinal pathology',
    },
    { name: '	Gynaecology pathology' },
    {
      name: '	Haematopathology',
    },
    {
      name: '	Liver pathology',
    },
    {
      name: '		Respiratory pathology',
    },
    {
      name: '		Renal/Urology pathology',
    },
  ];

  return (
    <Layout pageTitle='Department of Anatomic And Molecular Pathology | LUTH'>
      <NavOne />
      <PageHeader title='Department of Anatomic And Molecular Pathology' />
      <section className='course-details'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='course-details__content'>
                <div className='course-details__top'>
                  <div className='course-details__top-left'>
                    <h2 className='course-details__title'>
                      Department of Anatomic And Molecular Pathology
                    </h2>
                  </div>
                </div>
                <Tabs>
                  <div label='Overview'>
                    <Spoiler
                      maxHeight={300}
                      hideLabel='Hide'
                      showLabel='Show More'
                    >
                      <p className='course-details__tab-text'>
                        Welcome to the Department of Anatomic and Molecular
                        Pathology. The Department of Anatomic and Molecular
                        Pathology is one of the foundation departments when
                        Lagos University Teaching Hospital was established. With
                        over 70 staff strength, we have specialist consultants,
                        laboratory scientists, technicians, laboratory
                        assistants and other cadres of staff working as a team
                        to bring about a timely and efficient delivery of
                        quality diagnostic services. We offer excellent
                        pathology training to in-house residents and
                        supernumerary residents from different institutions
                        within the country.
                        <br />
                        <br />
                        <h2
                          className='blog-one__title'
                          style={{ marginTop: 2 }}
                        >
                          Sub-specialties
                        </h2>
                        <List
                          spacing='xs'
                          size='sm'
                          center
                          icon={
                            <ThemeIcon color='teal' size={24} radius='xl'>
                              <CircleCheck size={16} />
                            </ThemeIcon>
                          }
                        >
                          {services.map((data) => (
                            <List.Item key={data.name}>{data.name}</List.Item>
                          ))}
                        </List>
                      </p>
                    </Spoiler>
                  </div>
                  <div label='Services'>
                    <p className='course-details__tab-text'>
                      The Department of Anatomic and Molecular Pathology, LUTH
                      has standard laboratories for Histochemistry,
                      Immunohistochemistry (IHC), Molecular Pathology and
                      Cytogenetics. The department renders quality diagnostic
                      services in Cytopathology, Surgical Pathology and Autopsy
                      Pathology. There is a Fine Needle Aspiration Cytology
                      (FNAC) Clinic which run twice every week and we also
                      receive specimens for consultation in exfoliative and
                      gynaecological cytopathology. The department also has one
                      of the busiest autopsy diagnostic services in the country.
                    </p>
                  </div>
                  <div label='Staff'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Staff Details</h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={badmus} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. BADMOS, Kabir Bolarinwa</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS (IB), FMCPath (Nig.)
                            <br />
                            <span className='detail-head'>Specialty:</span> GIT
                            & Liver Pathology, Cytopathology                           
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={fehintola} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. BANJO, Adekunbiola Aina Fehintola</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, FMCPath , FIAC, MSc Clinical Cytology
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Gynaecologic Pathology/Cytopathology
                            <br />                            
                            <span className='detail-head'>
                              Area of Research/Focus:
                            </span>{' '}
                            HPV and Cancers
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={fatimah} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. ABDULKAREEM,Fatimah Biade</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBChB (Calabar),FMCPath (Nig). Fellow ISN, Fellow
                            ICRETT
                            <br />
                            <span className='detail-head'>Specialty:</span> GIT
                            & Liver Pathology, Renal Transplant Pathology                           
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={daramola} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. DARAMOLA, Adetola Olubunmi</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBChB (Calabar),FMCPath (Nig). Fellow ISN, Fellow
                            ICRETT
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Breast Pathology & Cytogenetics                           
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={charles} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> Prof ANUNOBI, Charles Chidozie</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS (Nig), MPH (Unilag),FMCPath (Nig)
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Urologic Pathology                           
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={awolola} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> Dr. AWOLOLA, Nichola Awodele </h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS,FMCPath
                            <br />
                            <span className='detail-head'>Specialty:</span> GIT
                            & Liver Pathology, Cytopathology                           
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={osadolor} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3> Dr. MOMOH, Martins Osadolor </h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS,FMCPath (Nig.), Cert in Molecular Pathology
                            (Hinxton, UK)
                            <br />
                            <span className='detail-head'>Specialty:</span> GIT
                            & Liver Pathology                           
                          </p>
                        </div>
                      </div>
                    </React.Fragment>
                  </div>
                  <div label='Contact'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Departmental Email</h2>
                      <p className='course-details__tab-text'>
                        <a href='mailto:ampa@luth.gov.ng'>                        
                        ampa@luth.gov.ng
                      </a>
                      </p>
                    </React.Fragment>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default Anatomic;
